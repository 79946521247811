import {fetchData} from "utils/fetch"
import {DefaultTableProperties} from "constants/table";
import {
    Churches,
    Division,
    DivisionResponse,
    Event,
    EventDetail,
    EventStatus, MinistriesResponse,
    Ministry,
    MinistryByDivision,
    MinistryType
} from "./type";
import type {MinistrySetup, MinistrySetupDetail} from '../ministry-setup/type';

type GetEventParams = {
    division: string;
    churches?: string;
    search?: string;
    page?: number;
}

export const getRefChurches = async (params: { churches: string }): Promise<Churches[]> => {
    try {
        //const response = await fetchData<Churches[]>(`/v1/church/${params.churches}`);
        const response = await fetchData<Churches[]>(`/v1/churches`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

// export const getRefMinistry = async (params: { churches: string }): Promise<Ministry[]> => {
//     try {
//         const response = await fetchData<Ministry[]>(`/v1/ministry-type/${params.churches}`);
//         console.log(response)
//         if (response?.data?.length) {
//             return response.data;
//         }
//
//         throw response;
//     } catch (err) {
//         return [];
//     }
// }

export const getAllMinistries = async (): Promise<Ministry[]> => {
    try {
        const response = await fetchData<Ministry[]>(`/v1/ministries`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}


export const getRefMinistry = async (params: { typeId: number }): Promise<Ministry[]> => {
    try {
        const response = await fetchData<Ministry[]>(`/v1/ministries?typeId=${params.typeId}`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

export const getRefMinistryOptions = async (params: { typeId: number }): Promise<Division[]> => {
    try {
        const data = await getRefDivision();
        if (data?.length) {
            // Filter items where ministryTypeId matches params.typeId
            return data.filter(item => item.ministryTypeId === (params.typeId ?? 1));
        }

        throw new Error("No data found");
    } catch (err) {
        console.error("Error fetching or filtering data:", err);
        return [];
    }
};


export const getRefMinistryByDivision = async (params: { id: string | undefined }): Promise<Ministry[]> => {
     try {
        const response = await fetchData<MinistriesResponse>(`/v1/divisions/${params.id}/ministries`);
        if (response.data.data.rows) {
            return response.data.data.rows
        }

        throw response;
    } catch (err) {
        return [];
    }
};

export const getRefMinistryType = async (): Promise<MinistryType[]> => {
    try {
        const response = await fetchData<MinistryType[]>(`/v1/ministry-type`);
        if (response?.data?.length) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return [];
    }
}

export const getRefDivision = async (): Promise<Division[]> => {
    try {
        const response = await fetchData<DivisionResponse>(`/v1/divisions?page=1&perpage=500`, {});
        console.log(response?.data?.rows.length);

        if (response?.data?.rows?.length) {
            return response.data.rows;
        }

        throw response;
    } catch (err) {
        console.error("Error fetching divisions:", err);
        return []
    }
}

export const getEvents = async (params: GetEventParams): Promise<{ rows: Event[], totalRecords: number }> => {
    try {
        const response = await fetchData<{
            rows: Event[],
            totalRecords: number
        }>(`/v1/servolution/events/${params.division}/${params.churches}`, {
            params: {
                page: params?.page ?? 1,
                perpage: DefaultTableProperties.paginationPerPage,
                search: params?.search ?? ''
            }
        });
        if (response?.data?.rows) {
            return {
                rows: Array.isArray(response.data.rows) ? response.data.rows : [],
                totalRecords: response.data.totalRecords ?? 0
            }
        }

        throw response;
    } catch (err) {
        return {rows: [], totalRecords: 0}
    }
}

export const getMinistrySetup = async (params: GetEventParams & { eventId: string }): Promise<{
    rows: MinistrySetup[],
    totalRecords: number
}> => {
    try {
        const response = await fetchData<{
            rows: MinistrySetup[],
            totalRecords: number
        }>(`/servolution/ministry/setup/${params.eventId}/${params.division}`, {
            params: {
                page: params?.page ?? 1,
                perpage: DefaultTableProperties.paginationPerPage,
                search: params?.search ?? ''
            }
        });
        if (response?.data?.rows) {
            return {
                rows: Array.isArray(response.data.rows) ? response.data.rows : [],
                totalRecords: response.data.totalRecords ?? 0
            }
        }

        throw response;
    } catch (err) {
        return {rows: [], totalRecords: 0}
    }
}

export const getMinistrySetupById = async (params: {
    eventId: string,
    divisionId: string
}): Promise<MinistrySetupDetail[] | null> => {
    try {
        const response = await fetchData<MinistrySetupDetail[]>(`/servolution/ministry/setup/details/${params.eventId}/${params.divisionId}`);
        if (response?.data?.length) {
            return response.data;
        }
        throw response;
    } catch (err) {
        return [];
    }
}

export const getEventById = async (eventId: string): Promise<EventDetail | null> => {
    try {
        const response = await fetchData<EventDetail>(`/v1/servolution/events/${eventId}`);
        if (response?.data) {
            return response.data;
        }

        throw response;
    } catch (err) {
        return null;
    }
}

export const postEvent = async (data: any): Promise<any> => {
    try {
        const response = await fetchData<any>(`/v1/servolution/events`, {
            method: 'POST',
            data
        });
        if (response?.data) {
            return response;
        }
        throw response;
    } catch (err) {
        throw err;
    }
}

export const postEventDuplicate = async (eventId: string): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events/${eventId}/duplicate`, {
            method: 'POST'
        });
        if (response?.data) {
            return response;
        }

        throw response;
    } catch (err: any) {
        throw err;
    }
}

export const patchEvent = async (eventId: string, data: any): Promise<any> => {
    try {
        const response = await fetchData<any>(`/v1/servolution/events/${eventId}`, {
            method: 'PATCH',
            data
        });
        if (response?.data) {
            return response;
        }
        throw response;
    } catch (err) {
        throw err;
    }
}

export const patchEventStatus = async (eventId: string, status: EventStatus): Promise<any> => {
    try {
        const response = await fetchData<any>(`/servolution/events/${eventId}`, {
            method: 'PATCH',
            data: {status}
        });
        if (response?.data) {
            return response;
        }

        throw response;
    } catch (err: any) {
        throw err;
    }
}